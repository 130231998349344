import api from "@/api";
import { FinallySubjectResultListRow, homeDate, homeSubjectListRow } from "@/api/modules/home";
import { MonthData } from "@/api/modules/select";
import { ListOpions } from "@/models/type";
import { h } from "@vue/runtime-core";
import { Vue } from "vue-class-component";
import { RouterView } from "vue-router";
import SubjectCell from "../home/components/SubjectCell";
import './index.scss'

export default class Subject extends Vue {

  public monthList: Array<ListOpions> = []
  public monthId = 0
  public subjectList: Array<FinallySubjectResultListRow> = []
  public bannerList: Array<homeSubjectListRow> = []
  public typeList: Array<any> = []
  public subjectTypeList: Array<any> = []

  public activeIndex = 0
  public screenWidth = 0 // 屏幕宽度
  public tutorIndex = 0

  public tutorList: Array<TutorRow> = []

  getMonth(): void {
    const year = new Date().getFullYear() // 获取当年
    const month = new Date().getMonth() + 1 // 获取当月
    api.getMonth({ year }).then(res => {
      this.monthList = res.data.map((item: MonthData) => ({ name: item.fullName, value: item.id }))
      this.monthId = res.data.find((item: MonthData) => item.endMonth === month || item.startMonth === month)?.id
      this.getData()
      this.getSubject(this.monthId)
    })
  }

  getData(): void {
    api.home_content({ monthId: this.monthId }).then(res => {
      const data = res.data as homeDate
      this.bannerList = data.homeSubjectList
    })
  }

  getSubject(monthId: number): void {
    api.subject_activity({ monthId }).then(res => {
      // 课程
      const subjectList: Array<any> = []
      const finallySubjectResultList = res.data.finallySubjectResultList as Array<FinallySubjectResultListRow>
      this.typeList = finallySubjectResultList
      finallySubjectResultList.forEach(item => {
        item.smsSubjectAndChapterDtoList.forEach(i => {
          i.subjectTypeName = item.subjectTypeName
          i.type = 'subject'
        })
        subjectList.push(...item.smsSubjectAndChapterDtoList)
      })
      this.subjectList = subjectList
    })
  }

  slideChangeTransitionEnd(activeIndex: number): void {
    this.activeIndex = activeIndex
    // 获取mounthId
    const mounthId = this.bannerList[activeIndex].monthId
    // 改变底部展示部分的信息
    this.getSubject(mounthId)
  }

  downLoadTable(): void {
    const url = this.bannerList[this.activeIndex].imgPath
    const a = document.createElement('a')
    a.href = url
    a.download = url
    window.open(url,'target');
    // a.click()
  }

  timetable(): JSX.Element {
    return <div class="subject-table">
      <el-row gutter={40}>
        <el-col span={4}>
          <h3 class="subject-table-title">{new Date().getFullYear()}</h3>
          <div class="subject-table-left">
            <ul>
              {this.bannerList.map((item, index) => <li
                class={index === this.activeIndex  && 'active'}
                onClick={() => this.slideChangeTransitionEnd(index)}
              >
                {item.fullName}
              </li>)}
            </ul>
            <div class="subject-table-choose">
              {this.typeList.map(item => <div>{item.subjectTypeName}课程</div>)}
            </div>
          </div>
        </el-col>
        <el-col span={20}>
          <div class="subject-table-right">
            <h3 class="subject-table-title" style="text-align: left">{this.bannerList[this.activeIndex]?.title}</h3>
            <img src={this.bannerList[this.activeIndex]?.imgPath} alt={this.bannerList[this.activeIndex]?.title} />
            <div class="subject-table-download" onClick={this.downLoadTable}>下载时间表</div>
          </div>
        </el-col>
      </el-row>
    </div>
  }

  subjectFilter(value: string): string {
    return this.subjectTypeList.find(item => item.id === +value)?.name
  }

  teacherTeam(): JSX.Element {
    return <div class="subject-teacher">
      <h3>导师阵容</h3>
      <div class="subject-teacher-container">
        <el-row class="subject-teacher-container-show">
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <img src={this.tutorList[this.tutorIndex]?.imgUrl} alt="" />
          </el-col>
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div class="subject-teacher-container-info">
              <div>
                <div class="subject-teacher-container-name">{this.tutorList[this.tutorIndex]?.title}</div>
                <div class="subject-teacher-container-job">{this.tutorList[this.tutorIndex]?.identity}</div>
                <span class="subject-teacher-container-border" />
              </div>
              <div class="subject-teacher-container-content">{this.tutorList[this.tutorIndex]?.introduce}</div>
              <div class="subject-teacher-container-bottom">
                <h4>主讲课程</h4>
                <div class="text">{this.subjectFilter(this.tutorList[this.tutorIndex]?.subjectType)}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="subject-teacher-bottom">
          { this.tutorList.map((item, index) => <div
            class="subject-teacher-bottom-cell"
            onMouseenter={() => this.tutorIndex = index}
            style={{
              opacity: this.tutorIndex === index ? 1 : 0.38
            }}
          >
            <div class="top">
              <img src={ item.imgUrl } alt={item.title} />
              <div class="text">{ item.title }</div>
            </div>
            <div class="job">{item.identity}</div>
          </div>) }
        </div>
      </div>
    </div>
  }

  getList(): void {
    Promise.all([
      api.getTutorList(),
      api.getSysSelectTypeOption(3)
    ]).then(res => {
      this.tutorList = res[0].data.list
      this.subjectTypeList = res[1].data
    })
  }

  /**
   * @description 去详情
   * @author Jinx
   * @date 2021-10-17 16:01:59
   * @param {*} pRow
   * @memberof Subject
   */
  onCellClick(pRow: any): void {
    const { id } = pRow // 详情id
    this.$router.push({
      name: 'SubjectDetail',
      params: { id }
    })
  }

  render(): JSX.Element {
    return this.$route.path === '/subject'
      ? <div
        class="subject"
        style={{
          paddingBottom: 100 * (this.tutorList.length / 3) + 'px'
        }}
      >
        {/* <h2 class="subject-title">课程总览</h2> */}
        {/* {this.timetable()} */}
        {/* {h(SubjectCell, {
          list: this.subjectList,
          screenWidth: this.screenWidth,
          onCellClick: this.onCellClick
        })} */}
        {this.teacherTeam()}
      </div>
      : <RouterView />
  }

  mounted(): void {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
          this.screenWidth = document.body.clientWidth;
      })()
    }
    this.getMonth()
    this.getList()
  }
}

export interface TutorRow {
  createTime: Date
  deleteStatus: number
  email: string
  id: number
  identity: string
  imgUrl: string
  introduce: string
  phone: string
  status: number
  subjectType: string
  title: string
  updateTime: Date
}
